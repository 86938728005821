import { graphql } from 'gatsby';
import React from 'react';

import { NewsArticlesQueryQuery } from '../common/types/graphql';
import { PageLayout } from '../components/layout/page';

export const query = graphql`
  query NewsArticlesQuery($id: String!, $originalId: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsNewsArticle(id: { eq: $id }) {
      model {
        apiKey
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      category
      description
      date
      originalId
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { crop: "focalpoint", fit: "crop", ar: "16:9" }
        )
      }
      previewImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { crop: "focalpoint", fit: "crop", ar: "16:9" }
        )
      }
      pdf {
        url
        filename
      }
      body {
        ...ModuleContact
        ...ModuleDownloadList
        ...ModuleExpandingList
        ...ModuleGallery
        ...ModuleHeading
        ...ModuleHero
        ...ModuleImage
        ...ModuleLatestNews
        ...ModuleNumberedList
        ...ModulePositionsList
        ...ModuleQuote
        ...ModuleText
        ...ModuleTextAndImage
        ...ModuleValuesList
        ...ModuleIntro
        ...ModuleVideo
      }
    }
    versions: allDatoCmsNewsArticle(
      filter: { slug: { ne: null }, originalId: { eq: $originalId } }
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const newsArticle = ({
  data,
  pageContext,
}: {
  data?: NewsArticlesQueryQuery;
  pageContext: any;
}) => {
  if (!data) {
    return <PageLayout data={data} pageContext={pageContext} />;
  }

  const articleUrl = new URL(
    `/${data.page.locale}/news/${data.page.slug}/`,
    data.settings.siteMetadata.siteUrl,
  );
  const facebookUrl = new URL('https://www.facebook.com/sharer.php');
  const twitterUrl = new URL('https://twitter.com/intent/tweet');

  facebookUrl.searchParams.set('u', articleUrl.toString());
  facebookUrl.searchParams.set('t', data.page.title);

  twitterUrl.searchParams.set('url', articleUrl.toString());
  twitterUrl.searchParams.set('text', data.page.title);

  const body = [...data.page.body];

  body.unshift({
    model: {
      apiKey: 'module_articles_block',
    },
    date: data.page.date,
    title: data.page.title,
    titleType: data.page.category,
    image: data.page.image,
  } as any);

  const indexOfLatestNews = body.findIndex(
    (block) => block.__typename === 'DatoCmsModuleLatestNews',
  );

  body.splice(indexOfLatestNews !== -1 ? indexOfLatestNews : body.length, 0, {
    id: data.page.originalId,
    model: {
      apiKey: 'module_article_share_block',
    },
    pdf: data.page.pdf,
    email: `mailto:?subject=${encodeURIComponent(
      data.page.title,
    )}&body=${encodeURIComponent(data.page.description)}${encodeURIComponent(
      '\n\n',
    )}${encodeURIComponent(articleUrl.toString())}`,
    twitter: twitterUrl.toString(),
    facebook: facebookUrl.toString(),
  } as any);

  return (
    <PageLayout
      data={{ ...data, page: { ...data.page, body } }}
      pageContext={pageContext}
    />
  );
};

export default newsArticle;
